import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress } from 'semantic-ui-react'

// custom build components
import TextInput from './TextInput'
import TextArea from './TextArea'

// contact services
import { submitContactForm } from '../services/bullarum.service'

// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  businessName: Yup.string()
    .required('Business name is required')
    .trim()
    .min(4, 'Invalid input')
    .max(40, 'Invalid input'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format')
    .required('Email is required'),
  message: Yup.string()
    .required('Message is required')
    .min(20, 'Minimum 20 characters')
    .max(100, 'Maximum 200 characters'),
  createdAt: Yup.date().default(new Date())
})

const defaultValues = {
  customerName: '',
  businessName: '',
  email: '',
  message: ''
}

const ContactUsForm = () => {
  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const handleSubmission = React.useCallback((values, actions) => {
    // sanitize data before sending
    const sanitizedData = {
      customerName: _.escape(_.trim(values.customerName)),
      businessName: _.escape(_.trim(values.businessName)),
      email: _.trim(values.email),
      message: _.escape(_.trim(values.message))
    }
    // submitting form value
    actions.setSubmitting(true)
    submitContactForm(sanitizedData)
      .then(res => {
        setIsSuccess(true)
        setSuccessMessage(res.data.message)
      })
      .catch(error => {
        setHasError(true)
        if (error.response) {
          setErrorMessage(error.response.data)
        } else {
          setErrorMessage('Something wrong happened. Please try again later!!!')
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
        actions.resetForm()
        setTimeout(() => {
          setHasError(false)
          setIsSuccess(false)
        }, 5000)
      })
  }, [])

  return (
    <>
      <br />
      <Formik
        initialValues={defaultValues}
        onSubmit={handleSubmission}
        validationSchema={ContactSchema}
        render={({ handleSubmit, isSubmitting, isValid }) => (
          <>
            <Form onSubmit={handleSubmit} noValidate className="attached fluid segment">
              {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
              <Grid stackable padded>
                <Grid.Row columns="2">
                  <Grid.Column>
                    <TextInput
                      name="customerName"
                      label="Your Name"
                      placeholder="Please enter your full name"
                      autoComplete="name"
                      required
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <TextInput
                      name="email"
                      label="Email"
                      placeholder="example@domain.com"
                      autoComplete="email"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <TextInput
                      name="businessName"
                      label="Business Name"
                      placeholder="Your business name"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <TextArea
                      name="message"
                      label="Message"
                      placeholder="Tell us about your question"
                      minRow="10"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
                <div
                  className="g-recaptcha"
                  data-sitekey="6LeMKsIUAAAAAP5BqUuVVPJAke81nZojIuxiYhVM"
                />
                <Grid.Row>
                  <Grid.Column>
                    <button type="submit" className="ui button" disabled={isSubmitting || !isValid}>
                      Submit
                    </button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            {hasError && (
              <Message attached="bottom" negative>
                <Message.Header>Error!!!</Message.Header>
                {errorMessage}
              </Message>
            )}
            {isSuccess && (
              <Message attached="bottom" positive>
                <Message.Header>Your message has been delivered!!!</Message.Header>
                {successMessage}
              </Message>
            )}
          </>
        )}
      />
    </>
  )
}

export default ContactUsForm

/* google recaptcha button
  <button
    className="ui button g-recaptcha"
    data-sitekey="your_site_key"
    disabled={isSubmitting || !isValid}
    data-callback="onSubmit"
  >
    Submit
  </button>
*/
