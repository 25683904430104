import React from 'react'
import { Grid, Card } from 'semantic-ui-react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import ContactUsForm from '../components/ContactUsForm'

const ContactUsPage = () => (
  <>
    <Layout>
      <SEO
        title="Contact Us"
        description="Looking for the safe and beautiful pedicure spa chairs for your salon? Our sales team is here to assist you to choose the best Contego spa chair series with your salon. CALL US at (714) 895-8865 NOW to get instant help!!!"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container-fluid px-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5519.927823646978!2d-117.95541615301237!3d33.774299626182454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2614190e99f7%3A0x7866e29281e323a1!2sContego%20Spa%20Designs!5e0!3m2!1sen!2sus!4v1570034391494!5m2!1sen!2sus"
          width="100%"
          height="450"
          frameBorder="0"
          className="m-0"
          title="administrative location"
        />
      </div>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center px-0 py-4">
        <h1 className="w-100 text-center">ADMINISTRATIVE</h1>
        <div className="d-flex flex-column align-items-center text-center">
          <a href="https://goo.gl/maps/z7iY7nsy7sLVv1f28" rel="noopener noreferrer" target="_blank">
            12856 Brookhurst St, Garden Grove, CA 92840 USA
          </a>
          <a href="tel:714-895-8865">(714) 895-8865</a>
          <a href="mailto:contact@contegospa.com">contact@contegospa.com</a>
          <p>
            <strong className="text-secondary">MON-SUN</strong> 9:00AM - 5:30PM
          </p>
        </div>
        <br />
      </div>
      <div className="container-fluid bg-light px-0 py-4">
        <br />
        <div className="p-4 rounded">
          <h1 className="text-center">OUR SHOWROOMS</h1>
          <p className="text-center">
            Stop by to experience <strong>THE WORLD'S ONLY DISPOSABLE PEDICURE SPA JET™</strong>
          </p>
        </div>
        <br />
        <Grid columns="2" stackable padded>
          <Grid.Column>
            <Card fluid>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5519.927823646978!2d-117.95541615301237!3d33.774299626182454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2614190e99f7%3A0x7866e29281e323a1!2sContego%20Spa%20Designs!5e0!3m2!1sen!2sus!4v1570034391494!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameBorder="0"
                className="m-0"
                title="garden grove showroom"
              />
              <Card.Content className="text-center py-4">
                <Card.Header>
                  <h2 className="text-center">Garden Grove, CA</h2>
                </Card.Header>
                <Card.Description className="text-center">
                  <a
                    href="https://goo.gl/maps/z7iY7nsy7sLVv1f28"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-center"
                  >
                    12856 Brookhurst St, Garden Grove, CA 92840 USA
                  </a>
                  <br />
                  <a href="tel:714-895-8865" className="text-center">
                    (714) 895-8865
                  </a>
                  <p>
                    <strong className="text-secondary">MON-SUN</strong> 9:00AM - 5:30PM
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9751.037284441582!2d-95.56976097480528!3d29.703475401994872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd96ba00d563e7808!2sCONTEGO%20SPA%20DESIGNS%20Houston%20Showroom!5e0!3m2!1sen!2sus!4v1570118953949!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameBorder="0"
                className="m-0"
                title="texas showroom"
              />
              <Card.Content className="text-center py-4">
                <Card.Header>
                  <h2 className="text-center">Houston, TX</h2>
                </Card.Header>
                <Card.Description className="text-center">
                  <a
                    href="https://goo.gl/maps/tS79wr6Dnm8vazNf8"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-center"
                  >
                    6787A Wilcrest Dr, Houston, TX 77072 USA
                  </a>
                  <br />
                  <a href="tel:832-436-2300" className="text-center">
                    (832) 436-2300
                  </a>
                  <p className="d-block mb-0">
                    <strong className="text-secondary">MON - THUR</strong> 10:00AM - 5:00PM
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary"> SAT</strong> 9:00AM - 3:00PM
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary"> SUN</strong> 10:00AM - 5:00PM
                  </p>{' '}
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
        <br />
      </div>
      <div className="container-fluid px-0 py-4">
        <br />
        <h1 className="text-center px-5">CONTACT OUR SALES TEAM</h1>
        <p className="text-center px-5">
          Our team is here to assit you to choose the best and safest pedicure chairs for your
          sallon
        </p>
        <div className="container">
          <ContactUsForm />
        </div>
        <br />
      </div>
    </Layout>
  </>
)

export default ContactUsPage
